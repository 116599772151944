export const ADDRESS_DETAILS_FORM_MAPPER = {
  pincode: "pincode",
  address: "address",
  city: "city",
  state: "state",
  docType: "docType",
  password: "password",
  file: "file",
  frontDoc: "frontDoc",
  backDoc: "backDoc",
  dob: "dob",
  fatherName: "fatherName",
};

export const ADDRESS_TYPE_NAME_MAPPER = {
  DL: "Driving License",
  PASSPORT: "Passport",
  VOTER_ID_CARD: "Voter Identity Card",
  // AADHAAR: "AADHAAR",
};

export const ADDRESS_PROOF_OF_IDENTITY = [
  // {
  //   name: "Aadhaar",
  //   value: "AADHAAR",
  //   numberOfDocs: 2,
  // },
  {
    name: "Driving License",
    value: "DL",
    numberOfDocs: 2,
  },
  {
    name: "Passport",
    value: "PASSPORT",
    numberOfDocs: 2,
  },
  {
    name: "Voter ID",
    value: "VOTER_ID_CARD",
    numberOfDocs: 2,
  },
];

export const DEFAULT_ADDRESS_DETAILS = {
  [ADDRESS_DETAILS_FORM_MAPPER.pincode]: "",
  [ADDRESS_DETAILS_FORM_MAPPER.address]: "",
  [ADDRESS_DETAILS_FORM_MAPPER.city]: "",
  [ADDRESS_DETAILS_FORM_MAPPER.state]: "",
  [ADDRESS_DETAILS_FORM_MAPPER.docType]: "",
  [ADDRESS_DETAILS_FORM_MAPPER.dob]: "",
  [ADDRESS_DETAILS_FORM_MAPPER.fatherName]: "",
  [ADDRESS_DETAILS_FORM_MAPPER.file]: null,
  [ADDRESS_DETAILS_FORM_MAPPER.frontDoc]: null,
  [ADDRESS_DETAILS_FORM_MAPPER.backDoc]: null,
};

export const ERROR_MESSAGES = {
  consecutiveSpaces: "consecutive spaces are not allowed",
  pincode: "Enter the correct PIN code",
  emptyField: "This information is required",
  general: "Please enter valid",
  uploadDocument: "Document upload is mandatory",
};

export const ACCOUNT_DORMANT_STATUS = {
  failed: "failed",
  reactivated: "reactivated",
  reKycInprocess: "rekyc_inprocess",
};

export const AADHAR_SEEDING_STATUS = {
  success: {
    imageName: "kyc_esign.svg",
    title: "PAN is linked with Aadhaar",
    subtitle:
      "In case you have linked your PAN and Aadhaar recently, please wait for 7-10 days before it reflects in your invested products.",
  },
  failed: {
    imageName: "kyc_rejected.svg",
    title: "PAN not linked with Aadhaar",
    subtitle: "Please link your PAN with Aadhaar to proceed further",
  },
};

export const AADHAR_SEEDING_STATUS_MESSAGE = {
  transactions:
    "You cannot make any transactions until your PAN and Aadhaar are linked.",
  accountOpening:
    "Pan must be linked with aadhaar to proceed with account opening",
};
