export const separatorStyleOverRides = (colors) => {
  return {
    root: {
      borderColor: colors.supporting.athensGrey,
      borderBottomWidth: "1px",
      backgroundColor: colors.supporting.athensGrey,
    },
    inset: {
      marginRight: "16px",
    },
    middle: {
      margin: "0px 16px",
    },
  };
};
