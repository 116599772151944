const DEFAULT_ROUTE_NAME = "/mfV2";

export const MF_PATHNAME_MAPPER = {
  recommendations: `${DEFAULT_ROUTE_NAME}/recommended-funds`,
  changeRiskProfile: `${DEFAULT_ROUTE_NAME}/change-risk-profile`,
  selectSipDate: `${DEFAULT_ROUTE_NAME}/select-sip-date`,
  editFunds: `${DEFAULT_ROUTE_NAME}/edit-funds`,
  howWeRecommendFunds: `${DEFAULT_ROUTE_NAME}/how-we-recommend-funds`,
  investForGoalLanding: `${DEFAULT_ROUTE_NAME}/invest-for-goal-landing`,
  targetYear: (subtype) =>
    `${DEFAULT_ROUTE_NAME}/saveforgoal/${subtype}/target-year`,
  childeducation: `${DEFAULT_ROUTE_NAME}/saveforgoal/childeducation/target-year`,
  retirement: `${DEFAULT_ROUTE_NAME}/saveforgoal/retirement/target-year`,
  targetAmount: `${DEFAULT_ROUTE_NAME}/saveforgoal/target-amount`,
  investForGoalSelect: `${DEFAULT_ROUTE_NAME}/saveforgoal/amount`,
  investmentType: (flow) => `${DEFAULT_ROUTE_NAME}/landing/${flow}`,
  buildwealth: `${DEFAULT_ROUTE_NAME}/landing/buildwealth`,
  investsurplus: `${DEFAULT_ROUTE_NAME}/landing/investsurplus`,
  savetax: `${DEFAULT_ROUTE_NAME}/save-tax-landing`,
  savetaxAmount: `${DEFAULT_ROUTE_NAME}/landing/savetax`,
  saveForGoal: `${DEFAULT_ROUTE_NAME}/landing/saveforgoal`,
  nfoDetails: `${DEFAULT_ROUTE_NAME}/nfo-fund-details`,
  nfoFunds: `${DEFAULT_ROUTE_NAME}/nfo-funds`,
  nfoOrder: `${DEFAULT_ROUTE_NAME}/nfo-order`,
  paymentSuccess: `${DEFAULT_ROUTE_NAME}/payment-success`,
};

export const PENNY_VERIFICATION_MAPPER = {
  addAdditionalBank: {
    title: "Bank verification pending",
    subtitle:
      "You will have to wait until your bank verification is complete. To start investing right away, either upload required documents or add a new bank account",
    buttonTitle: "MANAGE BANK ACCOUNT",
    dataAid: "bankVerification",
  },
  default: {
    title: "Bank verification pending",
    subtitle: "You will have to wait until your bank verification is complete.",
    buttonTitle: "OKAY",
    dataAid: "bankVerification",
  },
};
