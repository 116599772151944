const FinityTheme = {
  colors: {
    primary: {
      brand: '#675AF6',
      100: '#EFEEFB',
      200: '#C6C2F9',
      300: '#B9B3F9',
      400: '#9D95F8',
      500: '#8279F8',
      600: '#4F44D0',
    },
    action: {
      brand: '#675AF6',
      200: '#DCDAFF',
      300: '#B9B3F9',
      400: '#9D95F8',
      500: '#8279F8',
      600: '#4F44D0',
    },
    secondary: {
      profitGreen: {
        200: '#E0F8EE',
        300: '#71DEB2',
        400: '#33CF90',
      },
      mango: {
        200: '#FFF5D9',
        300: '#FFD766',
        400: '#FFBD00',
      },
      lossRed: {
        200: '#F8E4E5',
        300: '#E39298',
        400: '#D04954',
      },
      blue: {
        200: '#E6F2FE',
        300: '#9CCCFA',
        400: '#5AAAF6',
      },
      coralOrange: {
        200: '#FFEBE4',
        300: '#FFA283',
        400: '#FE794D',
      },
    },
    content: {
      primary: '#132056',
      secondary: '#5A6389',
      tertiary: '#888FAF',
    },
    supporting: {
      cadetBlue: '#ADB1C3',
      gainsboro: '#DCDEE6',
      athensGrey: '#ECECF1',
      grey: '#F8F8FA',
      white: '#FFFFFF',
    },
  },
  button: {
    borderRadius: 12
  },
  fontFamily: ['Rubik', 'Roboto', 'sans-serif'],
  fontWeight: {
    Bold: 700,
    Medium: 500,
    Regular: 400,
  }
};

export default FinityTheme;
