import { addParamsToPath, generateBasePathHelper } from "../helpers"

const getPathWithBaseRoute = generateBasePathHelper("/my-account");

export const MY_ACCOUNT_ROUTES = {
  LANDING: getPathWithBaseRoute(),
  PROFILE_DETAILS: getPathWithBaseRoute("/profile"),
  BANK_AND_MANDATE_LIST: getPathWithBaseRoute("/bank-list"),
  BANK_AND_MANDATE_DETAIL: (bankId) => getPathWithBaseRoute(`/bank-details/${bankId}`),
  STATEMENTS: getPathWithBaseRoute("/statements"),
  GENERATE_STATEMENT: (statementType) => getPathWithBaseRoute(`/generate-statement/${statementType}`),
  GENERATE_TAX_STATEMENT: (statementType) => getPathWithBaseRoute(`/generate-tax-statement/${statementType}`),
  GENERATE_PORTFOLIO_STATEMENT: getPathWithBaseRoute(`/generate-portfolio-statement`),
  SETTINGS: getPathWithBaseRoute("/settings"),
  SECURITY_SETTINGS: getPathWithBaseRoute("/settings/security"),
  EMAIL_SUBSCRIPTIONS: getPathWithBaseRoute("/settings/email-subscriptions"),
  ORDER_PREFERENCES: getPathWithBaseRoute("/settings/order-preferences"),
  FOLLOW_US: getPathWithBaseRoute("/follow-us"),
  ACCOUNT_RELATED_FORMS: getPathWithBaseRoute("/account-forms"),
  FORGOT_PIN: getPathWithBaseRoute("/forgot-pin"),
  RESET_PIN_VERIFY: getPathWithBaseRoute("/reset-pin-verify"),
  ENTER_NEW_PIN: getPathWithBaseRoute("/new-pin"),
  VERIFY_PIN_OTP: getPathWithBaseRoute("/verify-otp"),
  SET_PIN: (comingFrom) => addParamsToPath(
    getPathWithBaseRoute("/set-pin"),
    comingFrom
  ),
  CONFIRM_PIN: (comingFrom) => addParamsToPath(
    getPathWithBaseRoute("/confirm-pin"),
    comingFrom
  ),
};