import { MAXIMUM_SAVABLE_AMOUNT } from "businesslogic/constants/mf";
import { formatAmountInr } from "businesslogic/utils/common/functions";
import { MF_PATHNAME_MAPPER } from "./mf";

export const RESTRICTED_FEATURES = [
  "nps",
  "insurance",
  "instaredeem",
  "taxFiling",
  "passiveIndexFunds",
  "riskprofile",
  "fhc",
];

export const WEBAPP_LANDING_PATHNAME_MAPPER = {
  landing: "/",
  nfo: MF_PATHNAME_MAPPER.nfoFunds,
  nfoCheckout: MF_PATHNAME_MAPPER.nfoOrder,
  passiveIndexFunds: "/passive-index-funds/landing",
  instaredeem: "/invest/instaredeem",
  buildwealth: MF_PATHNAME_MAPPER.buildwealth,
  nps: "/nps/info",
  diy: "/invest/explore-v2",
  parkmoney: MF_PATHNAME_MAPPER.investsurplus,
  savegoal: MF_PATHNAME_MAPPER.investForGoalLanding,
  insurance: "/group-insurance",
  mf: "/invest",
  elss: MF_PATHNAME_MAPPER.savetax,
  ipo: "/market-products",
  ipoBanner: "/market-products",
  freedomplan: "/freedom-plan",
  myAccount: "/my-account",
  help: "/help",
  portfolio: "/reports",
  equity: "/diyv2/Equity/landing",
  debt: "/diyv2/Debt/landing",
  hybrid: "/diyv2/Hybrid/landing",
  diySearch: "/diyv2/invest/search",
  notification: "/notification",
  investingOptions: "/landing/view-all/mf",
  allCategories: "/landing/view-all/category",
  bankList: "/landing/bank-list",
  refer: "/refer-and-earn/landing",
  taxFiling: "/tax-filing",
  education: MF_PATHNAME_MAPPER.childeducation,
  retirement: MF_PATHNAME_MAPPER.retirement,
  equityKyc: "/kyc/web",
  nifty: "/passive-index-funds/nifty_backers/fund-list",
  sensex: "/passive-index-funds/sensex_backers/fund-list",
  riskProfile: "/risk/result-new",
  fhc: "/fhc",
  investExplore: "/invest/explore",
  smallcase: "/direct/smallcase",
};

export const MANAGE_INVESTMENTS = [
  {
    icon: "portfolio.svg",
    title: "Portfolio",
    id: "portfolio",
  },
  {
    icon: "account.svg",
    title: "Account",
    id: "myAccount",
  },
  {
    icon: "help.svg",
    title: "Help",
    id: "help",
  },
];

export const INVESTMENT_OPTIONS = {
  stocks: {
    icon: "stocks.svg",
    title: "Stocks & F&O",
    subtitle: "Invest in your favourite companies",
    dataAid: "stocks",
    eventStatus: "stocks, f&o",
    id: "stocks",
  },
  ipo: {
    icon: "ipo.svg",
    title: "IPO & NCD",
    subtitle: "Invest in primary market products",
    eventStatus: "ipos",
    dataAid: "ipoSgbNcd",
    id: "ipo",
  },
  mf: {
    icon: "mf.svg",
    title: "Mutual funds",
    subtitle: "Top performing funds for your goals",
    eventStatus: "mutual funds",
    dataAid: "mutualFunds",
    id: "mf",
  },
  nps: {
    icon: "nps.svg",
    title: "National pension scheme",
    subtitle: "Invest today for a secure retirement",
    eventStatus: "national pension scheme",
    dataAid: "nps",
    id: "nps",
  },
  insurance: {
    icon: "io_insurance.svg",
    title: "Insurance",
    subtitle: "Build a safety net for your future",
    eventStatus: "Insurance",
    dataAid: "insurance",
    id: "insurance",
  },
  taxFiling: {
    icon: "tax_filing.svg",
    title: "Free tax filing",
    subtitle: "Save and file your ITRs fast",
    eventStatus: "tax filing",
    dataAid: "taxFiling",
    id: "taxFiling",
  },
  categoryViewAll: {
    icon: "view_all.svg",
    title: "All categories",
    subtitle: "Choose as per your unique needs",
    eventStatus: "all categories",
    id: "allCategories",
    dataAid: "viewAll",
  },
  buildwealth: {
    icon: "high_growth.svg",
    title: "High growth funds",
    subtitle: "Start with as low as ₹500 ",
    eventStatus: "high growth funds",
    id: "buildwealth",
    dataAid: "highGrowthFunds",
  },
  nfo: {
    icon: "new_fund.svg",
    title: "New fund offer",
    subtitle: "Invest in newly launched funds",
    eventStatus: "new fund offer(nfo) ",
    id: "nfo",
    dataAid: "newFund",
  },
  parkmoney: {
    icon: "park_money.svg",
    title: "Park your savings",
    subtitle: "Make your idle money work ",
    eventStatus: "park my savings",
    id: "parkmoney",
    dataAid: "parkMySavings",
  },
  viewAll: {
    icon: "view_all.svg",
    title: "View all",
    subtitle: "Invest as per your unique needs",
    eventStatus: "view all",
    id: "investingOptions",
    dataAid: "viewAll",
  },
  elss: {
    icon: "tax_saving.svg",
    title: "Tax saving funds",
    subtitle: `Save tax up to ${formatAmountInr(MAXIMUM_SAVABLE_AMOUNT)}`,
    eventStatus: "save tax",
    id: "elss",
    dataAid: "taxSavingFund",
  },
  savegoal: {
    icon: "invest_goal.svg",
    title: "Invest in a goal",
    subtitle: "Plans for every investment goal",
    eventStatus: "invest for a goal",
    id: "savegoal",
    dataAid: "goalFund",
  },
  // instaredeem: {
  //   icon: "insta_redeem.svg",
  //   title: "Insta redemption funds",
  //   subtitle: "24x7 instant withdrawal of funds",
  //   eventStatus: "Insta redemption funds",
  //   id: "instaredeem",
  //   dataAid: "instaRedemptionFund",
  // },
  passiveIndexFunds: {
    icon: "high_growth.svg",
    title: "Passive Index funds",
    subtitle: "Earn upto 2% more than active funds",
    eventStatus: "passive index funds",
    id: "passiveIndexFunds",
    dataAid: "passiveIndexFunds",
  },
  equity: {
    icon: `diy_equity.svg`,
    title: "Equity",
    subtitle: "Large, mid and small-sized companies",
    dataAid: "equity",
    eventStatus: "equity",
    id: "equity",
  },
  debt: {
    icon: "diy_debt.svg",
    title: "Debt",
    subtitle: "Stable returns with bonds & securities",
    dataAid: "debt",
    eventStatus: "debt",
    id: "debt",
  },
  hybrid: {
    icon: "diy_hybrid.svg",
    title: "Hybrid",
    subtitle: "Perfect balance of equity & debt",
    dataAid: "hybrid",
    eventStatus: "hybrid",
    id: "hybrid",
  },
};

export const EXPLORE_CATEGORIES = [
  {
    icon: `diy_eq_icon.svg`,
    title: "Equity",
    description: "Large, mid & small companies",
    dataAid: "equity",
    id: "equity",
  },
  {
    icon: "debt.svg",
    title: "Debt",
    description: "Low risk, stable returns",
    dataAid: "debt",
    id: "debt",
  },
  {
    icon: "hybrid.svg",
    title: "Hybrid",
    description: "Perfect balance of equity & debt",
    dataAid: "hybrid",
    id: "hybrid",
  },
];

export const FINITY_EXPLORE_CATEGORIES = [
  {
    icon: `nifty.svg`,
    title: "Nifty backers",
    dataAid: "nifity",
    id: "nifty",
  },
  {
    icon: "sensex.svg",
    title: "Sensex backers",
    dataAid: "sensex",
    id: "sensex",
  },
  {
    icon: `diy_eq_icon.svg`,
    title: "Equity Funds",
    dataAid: "equityFunds",
    id: "equity",
  },
];

export const EXPLORE_CATEGORY_DATA = {
  fisdom: {
    title: "Explore by categories",
    dataAid: "exploreCategories",
    options: EXPLORE_CATEGORIES,
  },
  finity: {
    title: "Our recommendations",
    dataAid: "ourRecommendations",
    options: FINITY_EXPLORE_CATEGORIES,
  },
};

export const FINITY_RECOMMENDATIONS = [
  {
    icon: `new_fund_offer.svg`,
    title: "New fund offer (NFO)",
    dataAid: "newFundOffer",
    id: "nfo",
  },
  {
    icon: "sensex.svg",
    title: "High growth funds",
    dataAid: "highGrowth",
    id: "buildwealth",
  },
  {
    icon: `tax_save.svg`,
    title: "Tax saving funds (ELSS)",
    dataAid: "taxSaving",
    id: "elss",
  },
  {
    icon: `view_more.svg`,
    title: "More investing options",
    dataAid: "moreInvestingOptions",
    id: "investingOptions",
  },
];

export const MF_EXPLORE_CATEGORY_DATA = {
  fisdom: {
    title: "Explore by categories",
    dataAid: "exploreCategories",
    options: EXPLORE_CATEGORIES,
  },
  finity: {
    title: "Finity recommendations",
    dataAid: "finityRecommendation",
    buttonData: {
      id: "investingOptions",
      title: "See All",
    },
    options: FINITY_RECOMMENDATIONS,
    className: "al-ec-content",
  },
};

export const FINANCIAL_TOOLS = [
  {
    icon: `risk.svg`,
    title: "Risk profiler",
    description: "Investment planning made easy",
    dataAid: "riskProfiler",
    id: "riskProfile",
  },
  {
    icon: "fhc.svg",
    title: "Financial health check",
    description: "Stay finacially fit",
    dataAid: "financialHealth",
    id: "fhc",
  },
  // {
  //   icon: "tax_calculator.svg",
  //   title: "Tax calucator",
  //   description: "Get an estimate of your income tax",
  //   dataAid: "taxCalculator",
  //   id: "taxCalculator",
  // },
];

export const REFERRAL_DATA = {
  success: {
    title: "Successful",
    subtitle: "You have applied referral code successfully",
    primaryButtonTitle: "OKAY",
    dataAid: "referralSuccessful",
    image: require(`assets/check_circled.svg`),
  },
  failed: {
    title: "Entered incorrect code	",
    subtitle: "You have entered an incorrect referral code",
    primaryButtonTitle: "OKAY",
    dataAid: "referralError",
    image: require(`assets/caution.svg`),
  },
  noInput: {
    title: "Referral code not entered",
    subtitle: "Please enter referral code to proceed",
    primaryButtonTitle: "Okay",
    dataAid: "referralError",
    image: require(`assets/caution.svg`),
  },
};

export const SHARE_REFERRAL_DATA = {
  fisdom: {
    dataAid: "referEarn",
    title: "Refer & earn",
    subtitle: "Invite as many friends as you can & earn up to ₹2,00,000",
    rightIcon: "share_refer.svg",
  },
  finity: {
    dataAid: "referEarn",
    title: "Refer & earn",
    subtitle: "Invite as many friends as you can & earn up to ₹2,00,000",
    rightIcon: "share_refer.svg",
  },
};

export const BOTTOMSHEET_KEYS = {
  openKycStatusDialog: "openKycStatusDialog",
  openCampaign: "openCampaign",
  openReferral: "openReferral",
  openAuthVerification: "openAuthVerification",
  openAccountAlreadyExists: "openAccountAlreadyExists",
  openPremiumOnboarding: "openPremiumOnboarding",
  openAccountClosureDialog: "openAccountClosureDialog",
};

export const CAMPAIGN_NAME_MAPPER = {
  tradingRestriction: "trading_restriction_campaign",
  dormantRestriction: "equity_dormant_restriction_campaign",
  aadharSeedingStatus: "pan_aadhar_seeding_restriction_campaign",
};

export const CAMPAIGNS_TO_SHOW_ON_PRIORITY = [
  CAMPAIGN_NAME_MAPPER.tradingRestriction,
  CAMPAIGN_NAME_MAPPER.dormantRestriction,
  CAMPAIGN_NAME_MAPPER.aadharSeedingStatus,
];

export const EVENT_STATUS_MAPPER = {
  stocks: "stocks, f & o",
  ipo: "ipo, sgb, ncd",
  portfolio: "portfolio",
  home: "home",
};

export const APPSTORAGE_KEYS = {
  isCampaignDisplayed: "isCampaignDisplayed",
  isAuthVerificationDisplayed: "isAuthVerificationDisplayed",
  isKycBottomsheetDisplayed: "isKycBottomsheetDisplayed",
  isPremiumBottomsheetDisplayed: "isPremiumBottomsheetDisplayed",
  isClosureEsignReadyBottomsheetDisplayed:
    "isClosureEsignReadyBottomsheetDisplayed",
  isClosureRejectedBottomsheetDisplayed:
    "isClosureRejectedBottomsheetDisplayed",
};

export const AUTH_VERIFICATION_DATA = {
  email: {
    icon: "email_verification.svg",
    title: "Verify your email address",
    subtitle: "Email verification is mandatory for investment as per SEBI",
    primaryButtonTitle: "Continue",
    dataAid: "emailAddress",
  },
  mobile: {
    icon: "mobile_verification.svg",
    title: "Verify your mobile number",
    subtitle: "Mobile verification is mandatory for investment as per SEBI",
    primaryButtonTitle: "Continue",
    dataAid: "mobileNumber",
  },
  accountExists: {
    showAuthExists: true,
    icon: "account_already_exists.svg",
    title: "Account already exists!",
    commonSubtitle: "is mandatory for investment as per SEBI",
    buttonTitle: "Edit",
    dataAid: "verification",
  },
};

export const PREMIUM_ONBORDING_MAPPER = {
  ground_premium: {
    title: `Premium Onboarding`,
    subtitle: `Congratulations! You have been selected for premium onboarding. Fast track your investment journey.`,
    primaryButtonTitle: "CONTINUE",
    oneButton: true,
    icon: "premium.svg",
    instant: true,
  },
  incomplete: {
    title: `Premium Onboarding`,
    subtitle: `Fast track your investment with premium onboarding`,
    primaryButtonTitle: "complete now",
    secondaryButtonTitle: "Not now",
    icon: "premium.svg",
  },
};

export const INVESTMENT_IDEAS = [
  {
    title: 'smallcase',
    subtitle: 'Invest in expert curated portfolios',
    iconName: 'investment_idea1.svg',
    iconProps: {
      isCommon: true
    },
    id: 'smallcase',
  }
]