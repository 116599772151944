import { compact } from "lodash-es";

export const generateBasePathHelper = (basePath) => (path) => path ? (basePath + path) : basePath;

export const addParamsToPath = (path, ...params) => {
  if (!params.length) return path;
  const paramsString =
    compact(params) // remove undefined, null, ""
      .map(param => `/${param}`) // get array of strings => ["/param1", "/param2", ..]
      .join(""); // merge array of strings => /param1/param2/...
  return path + paramsString;
}