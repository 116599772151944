import React, { useState } from 'react';
import { withStyles } from 'material-ui/styles';
import AppBar from 'material-ui/AppBar';
import Toolbar from 'material-ui/Toolbar';
import IconButton from 'material-ui/IconButton';
import Close from '@material-ui/icons/Close';
import SVG from 'react-inlinesvg';
import { getConfig } from 'utils/functions';

import back_arrow from 'assets/nav_back.svg';
import close_icn from 'assets/nav_close.svg';
import search from 'assets/icon_search.svg';
import notificationLogo from 'assets/ic_notification.svg';
import notificationBadgeLogo from 'assets/ic_notification_badge.svg';
import isEmpty from 'lodash/isEmpty';
import { storageService } from "utils/validators";
import '../theme/Style.scss';
import restart from 'assets/restart_nav_icn.svg';
import Drawer from '../../desktopLayout/Drawer';
import MenuIcon from "@material-ui/icons/Menu";
import ReferDialog from '../../desktopLayout/ReferralDialog';
import SettingsWithBadge from 'assets/ic_setting_active.svg';

const headerIconMapper = {
  back: back_arrow,
  close: close_icn,
  search: search,
  restart: restart,
  settings: SettingsWithBadge,
}
const Header = ({
  classes, title, count, total, current, goBack, edit, type, resetpage, handleReset,
  smallTitle, disableBack, provider, inPageTitle, hideHamburger, force_hide_inpage_title,
  topIcon, handleTopIcon, canSkip, onSkipClick, className, style, headerData = {}, new_header,
  notification, handleNotification, noBackIcon, customTopIconColor, noTopIconColor
}) => {
    const rightIcon = headerIconMapper[topIcon];
    const [referDialog, setReferDialog] = useState(false);
    const [mobileViewDrawer, setMobileViewDrawer] = useState(false);
    const campaign = storageService().getObject("campaign");
    const config = getConfig();
    const isGuestUser = storageService().getBoolean('guestUser');
    const isMobileDevice = config.isMobileDevice;
    const isWeb = config.Web;
    const partnerLogo = isWeb && !isEmpty(config.webLogo) ? config.webLogo : config.logo;
    const backgroundColor = !isWeb ? config.uiElements?.header?.backgroundColor : '';
    // const backButtonColor = (!isWeb || config.isIframe) ? config.styles?.backButtonColor : '';
    const notificationsColor = !isWeb || config.isSdk ? config?.styles.notificationsColor : '';
    const moneycontrolHeader = isMobileDevice && config.code === 'moneycontrol';
    const equityPayment = window.location.pathname.includes('pg/eq');

    const handleMobileViewDrawer = () => {
      setMobileViewDrawer(!mobileViewDrawer);
    };
    const handleReferModal = () => {
      if(!referDialog){
        setMobileViewDrawer(!mobileViewDrawer);
      }
      setReferDialog(!referDialog);
    };
    return (
      <AppBar position="fixed" color="primary" data-aid='app-bar'
      className={`Header transition ${classes.root} ${(!headerData.partnerLogo || moneycontrolHeader) ? 'header-topbar-white' : 'header-topbar-primary'} ${className || ''}`}
      style={style}
      >
        <Toolbar>
          {
            !noBackIcon &&
            <IconButton className={classes.menuButton} color="inherit" aria-label="Menu"
              onClick={headerData.goBack || goBack} 
              // Important Note:  whenever adding goBack func in headerData Container prop within component, do add native condition for correct back navigation 
              data-aid='iv_back'
            >
              {!disableBack && !headerData.hide_icon &&
              <SVG
                preProcessor={code => code.replace(/fill=".*?"/g, 'fill=' + (customTopIconColor ? customTopIconColor : !headerData.partnerLogo ? undefined : 'white'))}
                src={headerData ? headerIconMapper[headerData.icon || 'back'] : back_arrow}
              />
              }
              {(disableBack === true || disableBack === 'summary') && !headerData.hide_icon &&
              <Close color="primary"/>}
            </IconButton>
          }
          {
            headerData.partnerLogo && config.isMobileDevice && !(moneycontrolHeader && isWeb) &&
             <div className='sdk-header-partner-logo' style={{ width: config.logoWidth }} >
                <img src={require(`assets/${partnerLogo}`)} alt="partner logo" /> 
            </div>
          }

          {
            headerData.partnerLogo && moneycontrolHeader && isWeb &&
             <div className='sdk-header-partner-logo'>
                <img src={require(`assets/${config.colorLogo}`)} alt="partner logo" height={20}/> 
            </div>
          }

          {headerData.progressHeaderData && 
            <div className="toolbar-title">
           <div className="progress-bar">
            <div className="head">
              {headerData.progressHeaderData.title}
            </div>
            <div style={{ display: 'flex', flexFlow: 1 }}>

              {headerData.progressHeaderData.steps.map((step, index) => (
                <div className="journey-header-progress" key={index}>
                  <div className="indicator">
                    <div className  = {index === 0 ? 'tiny-hr' : index === headerData.progressHeaderData.steps.length - 1 ? 'large-hr' : 'hr'} >
                      <div className={`${index === 0 ? 'invisible-line' :
                      `line line-${headerData.progressHeaderData.steps[index - 1].status}`
                      }`} ></div></div>
                    <span className={`dot ${step.status}`}></span>
                    <div className={index === 0 ? 'large-hr' : index === headerData.progressHeaderData.steps.length - 1 ? 'tiny-hr' : 'hr'} 
                      
                      ><div className={`${index === headerData.progressHeaderData.steps.length - 1 ? 'invisible-line' :
                      `line line-${headerData.progressHeaderData.steps[index].status}`
                      }`}  ></div></div>
                  </div>
                  <div
                  style = {{
                    marginLeft : index === 0 ? '-50%' : index === headerData.progressHeaderData.steps.length - 1 ? '50%' : '0',
                    color: `${step.status === 'init' ? '#0A1D32' : '#C4C4C4'}`
                  }}
                  >{step.title}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      }

        {!headerData.progressHeaderData && 
          <>
            {
              !headerData.partnerLogo && 
              <div
                style={style}
                className={`${classes.flex},PageTitle ${new_header ? 'main-top-title-header' : 'main-top-title-header-old'} 
                ${inPageTitle ? 'slide-fade' : 'slide-fade-show'} ${className}`}
                >
                {title}
              </div>
            }
            {
              !(moneycontrolHeader && headerData.partnerLogo) &&
              <div className='header-right-nav-components'>
                {resetpage &&
                  <SVG
                  style={{marginLeft: 'auto', width:20}}
                  onClick={handleReset}
                  preProcessor={code => code.replace(/fill=".*?"/g, 'fill=' + (new_header && backgroundColor ?  getConfig().styles.secondaryColor : getConfig().styles.primaryColor))}
                  src={restart}
                />
                }
                {canSkip &&
                <span className="header-skip-text" onClick={onSkipClick} data-aid='button_skip'>SKIP</span>
                }
                {topIcon &&
                  <SVG
                  style={{marginLeft: '20px', width:25, cursor:'pointer'}}
                  onClick={handleTopIcon}
                  preProcessor={!noTopIconColor
                    ? code => code.replace(/fill=".*?"/g, 'fill=' + (customTopIconColor || (new_header && backgroundColor ?  getConfig().styles.secondaryColor : getConfig().styles.primaryColor)))
                    : undefined
                  }
                  src={rightIcon}
                />
                }
                {notification &&
                  <SVG
                  style={{marginLeft: '20px', width:25, cursor:'pointer'}}
                  onClick={handleNotification}
                  preProcessor={code => code.replace(/fill="#FFF"/, 'fill=' + notificationsColor)}
                  src={isEmpty(campaign) ? notificationLogo : notificationBadgeLogo}
                />
                }
                {isMobileDevice && isWeb && !hideHamburger && !config.isIframe && !isGuestUser && !equityPayment &&
                  <div className='mobile-navbar-menu'>
                    <IconButton onClick={handleMobileViewDrawer}>
                      <MenuIcon style={{color: customTopIconColor ? customTopIconColor : new_header && backgroundColor ?  getConfig().styles.secondaryColor : headerData.partnerLogo ? 'white' : getConfig().styles.primaryColor}}/>
                    </IconButton>
                    <Drawer mobileViewDrawer={mobileViewDrawer} handleMobileViewDrawer={handleMobileViewDrawer} handleReferModal={handleReferModal} />
                  </div>
                }
              </div>
          }
          
          </>
        }

        </Toolbar>
        {
          isMobileDevice &&
          <ReferDialog isOpen={referDialog} close={handleReferModal} />
        }
      </AppBar>
    )
  };

const styles = {
  root: {
    flexGrow: 1,
  },
  flex: {
    flex: 1,
    textAlign: '-webkit-center'
  },
  menuButton: {
    marginLeft: "-12px !important",
    // marginRight: 20,
  },
  headerWithData: {
    height: '80px',
    display: 'flex',
    alignItems: 'flex-start'
  }
};

export default withStyles(styles)(Header);