import { get, isEmpty } from "lodash-es";
import store from "../../dataLayer/store";

export const getIconColorsList = (colors) => {
  if (isEmpty(colors)) {
    const state = store.getState();
    colors = get(state, "webAppConfig.appThemeConfig.colors", []);
  }
  const iconColors = [
    colors.secondary.profitGreen[400],
    colors.secondary.coralOrange[400],
    colors.secondary.blue[400],
    colors.secondary.mango[400],
  ];
  return iconColors;
};

export const getIconColor = (index) => {
  const state = store.getState();
  const iconColors = get(state, "webAppConfig.iconColors", []);
  return iconColors[index % 4];
};
