/*
  Prop Description:
  src => source of the icon.
  fallBackImageSrc => use this prop to override the existing fallback image.
    - the fallback image will be rendered, if there is some error while loading the src image.
  size => this will maintain the 1:1 for the image ie. (height and width will be equals to size).
  height, width => use this prop, if the the ratio of height and width is not 1:1.
  style => custom html style object.
  className => custom className.

  NOTE:
  fallBackImageSrc => by default we are using require('assets/fallback_icon.svg')
  src => path of the image(avoid using this, instead use name prop)
  name => name of image
  isCommon => bool, which will define if the image is coming from common folder or not.

  Example with name prop
  1. Image inside common folder
  <Icon 
    name='add_icon.svg'
    isCommon
  />
  2. Image inside fisdom/finity folder
  <Icon 
    name='buyback.svg'
  />
*/

import './Icon.scss';
import fallbackImg from "assets/fallback_icon.svg";
import React, { useState, memo, useEffect, useMemo } from 'react';
import isFunction from 'lodash/isFunction';
import { getConfig } from '../../../utils/functions';
import IconSkeleton from './IconSkeleton';

const Image = (props) => {
  const {
    height,
    width,
    fallBackImageSrc,
    size,
    className,
    style,
    src,
    alt,
    onClick,
    dataAid,
    onLoad,
    name,
    isCommon,
  } = props;
  const imgWidth = width || size;
  const imgHeight = height || size;
  const [currentSrc, updateSrc] = useState(src);
  const [loaded, setLoaded] = useState(false);
  const { productName } = useMemo(getConfig, []);

  const onLoadImage = (event) => {
    setLoaded(true);
    if (isFunction(onLoad)) {
      onLoad(event);
    }
  };

  useEffect(() => {
    let newSource = '';
    if (src) {
      // this is maintained to support the existing features wherever src was used.
      newSource = src;
    } else if (name) {
      if (name.match(/http/i)) {
        newSource = name;
      } else {
        newSource = isCommon ? require(`assets/${name}`) : require(`assets/${productName}/${name}`);
      }
    } else {
      newSource = fallBackImageSrc;
    }
    updateSrc(newSource);
  }, [src, productName, isCommon, name]);
  const onError = () => {
    updateSrc(fallBackImageSrc);
  };

  return (
    <>
      {!loaded && (
        <IconSkeleton size={size} width={width} height={height} style={style} />
      )}
      <img
        src={currentSrc}
        style={{
          width: imgWidth,
          height: imgHeight,
          ...style,
          flexShrink: 0,
          display: !loaded ? "none" : "",
        }}
        className={`${isFunction(onClick) && "icon-clickable"} ${className}`}
        alt={alt}
        onClick={onClick}
        onLoad={onLoadImage}
        onError={onError}
        data-aid={`iv_${dataAid}`}
      />
    </>
  );
};

const Icon = memo(Image);
Icon.Skeleton = IconSkeleton;
export default Icon;

Image.defaultProps = {
  fallBackImageSrc: fallbackImg,
};
