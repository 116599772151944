/* -----------------------------------------------------------------*/
/*  Module: callbackWeb
/*  Apis for sending/receiving `native` callbacks
/* -----------------------------------------------------------------*/
import { EVENT_MANAGER_CONSTANTS } from './constants';
import eventManager from './eventManager';
import { getPartnerName, isMobile } from './functions';
import { getConfig } from './functions';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import store from '../dataLayer/store';
import { setBankList, setKyc, setPartner, setUser, updateAppStorage } from 'businesslogic/dataStore/reducers/app';


(function (exports) {

  function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    let byteCharacters = atob(b64Data);
    let byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      let byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    let blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  exports.native_receiver_image = function (listener) {
    listeners.push(listener);
  }

  exports.upload_blob = function (data_json_str) {

    for (let j = 0; j < listeners.length; j++) {
      let lis = listeners[j];
      if (lis.type === 'native_receiver_image') {
        lis.show_loader(true);
        listeners.splice(j, 1);
        break;
      }
    }
    // native_receiver_image({ type: 'native_receiver_image' })
    let d
    if (typeof window.Android !== 'undefined') {
      d = JSON.parse(data_json_str);  // Handle Exception
    } else if (isMobile.iOS() && typeof window.webkit !== 'undefined') {
      d = data_json_str;
    }
    for (let i = 0; i < listeners.length; i++) {
      let l = listeners[i];
      if (l.type === 'doc' && (l.doc_type === d.file_name || l.doc_type === d.doc_type)) {
        const { blobBase64, mime_type, file_type, ...otherProps } = d;
        let file = b64toBlob(blobBase64, mime_type || file_type, '');
        file.file_name = d.file_name_local;
        l.upload(file, otherProps);
        listeners.splice(i, 1);
        i--;
      }
    }
  }

  exports.login_required = function () {
    var callbackData = {};
    callbackData.action = "login";
    if (typeof window.Android !== "undefined") {
      window.Android.callbackNative(JSON.stringify(callbackData));
    } else if (isMobile.iOS() && typeof window.webkit !== "undefined") {
      window.webkit.messageHandlers.callbackNative.postMessage(callbackData);
    }
  };

  exports.open_canvas = function (listener) {
    listeners.push(listener);
    var callbackData = {};
    callbackData.action = "open_canvas";
    callbackData.action_data = { file_name: listener.doc_type };
    if (typeof window.Android !== "undefined") {
      window.Android.callbackNative(JSON.stringify(callbackData));
    } else if (isMobile.iOS() && typeof window.webkit !== "undefined") {
      window.webkit.messageHandlers.callbackNative.postMessage(callbackData);
    }
  };

  exports.open_2fa_module = function (listener) {
    listeners.push(listener);
    const { operation, request_code, show_intro = false } = listener;
    let callbackData = {};
    callbackData.action = "2fa_module";
    callbackData.action_data = { operation, request_code, show_intro };
    if (typeof window.Android !== "undefined") {
      window.Android.callbackNative(JSON.stringify(callbackData));
    } else if (isMobile.iOS() && typeof window.webkit !== "undefined") {
      window.webkit.messageHandlers.callbackNative.postMessage(callbackData);
    }
  };

  exports.verify_mpin = function (listener) {
    listeners.push(listener);
    const { request_code } = listener;
    let callbackData = {};
    callbackData.action = "2fa_expired";
    callbackData.action_data = { request_code };
    if (typeof window.Android !== "undefined") {
      window.Android.callbackNative(JSON.stringify(callbackData));
    } else if (isMobile.iOS() && typeof window.webkit !== "undefined") {
      window.webkit.messageHandlers.callbackNative.postMessage(callbackData);
    }
  };

  exports.on_native_result = function (data_json_str) {
    let json_data = {};
    if (data_json_str !== "" && typeof data_json_str === "string") {
      json_data = JSON.parse(data_json_str);
    } else if (typeof data_json_str === "object") {
      json_data = data_json_str;
    }
    for (let lis of listeners) {
      if (["VERIFY_MPIN", "REQ_SETUP_2FA"].includes(lis.request_code) && isFunction(lis.callback)) {
        lis.callback(json_data);
        break;
      }
    }
  }

  exports.open_camera = function (listener) {
    listeners.push(listener);
    let callbackData = {};
    callbackData.action = 'take_picture';
    callbackData.action_data = {
      file_name: listener.doc_type,
      check_liveness: listener.check_liveness
    };
    if (typeof window.Android !== 'undefined') {
      window.Android.callbackNative(JSON.stringify(callbackData));
    } else if (isMobile.iOS() && typeof window.webkit !== 'undefined') {
      window.webkit.messageHandlers.callbackNative.postMessage(callbackData);
    }
  }

  exports.open_gallery = function (listener) {
    listeners.push(listener);
    let callbackData = {};
    callbackData.action = 'pick_picture';
    callbackData.action_data = { file_name: listener.doc_type };
    if (typeof window.Android !== 'undefined') {
      window.Android.callbackNative(JSON.stringify(callbackData));
    } else if (isMobile.iOS() && typeof window.webkit !== 'undefined') {
      window.webkit.messageHandlers.callbackNative.postMessage(callbackData);
    }
  }

  exports.open_file = function (listener) {
    listeners.push(listener);
    let callbackData = {};
    callbackData.action = 'get_blob';
    callbackData.action_data = { file_name: listener.doc_type, mime_types: ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'image/bmp'] };
    if (typeof window.Android !== 'undefined') {
      window.Android.callbackNative(JSON.stringify(callbackData));
    } else if (isMobile.iOS() && typeof window.webkit !== 'undefined') {
      window.webkit.messageHandlers.callbackNative.postMessage(callbackData);
    }
  }

  exports.open_video_camera = function (listener) {
    listeners.push(listener);
    let callbackData = {};
    callbackData.action = "take_video";
    callbackData.action_data = { file_name: listener.doc_type, message: listener.message, otp: listener.ipv_code };
    if (typeof window.Android !== "undefined") {
      window.Android.callbackNative(JSON.stringify(callbackData));
    } else if (isMobile.iOS() && typeof window.webkit !== "undefined") {
      window.webkit.messageHandlers.callbackNative.postMessage(callbackData);
    }
  };


  exports.get_partner_code = function () {
    var callbackData = {};
    callbackData.action = "get_partner_code";
    if (typeof window.Android !== "undefined") {
      window.Android.callbackNative(JSON.stringify(callbackData));
    } else if (isMobile.iOS() && typeof window.webkit !== "undefined") {
      window.webkit.messageHandlers.callbackNative.postMessage(callbackData);
    }
  };

  exports.set_partner_code = function (data) {
    document.body.setAttribute("id", data);
    document.getElementById("logo").src = "assets/img/" + data + ".png";
  };

  exports.post_error = function (data) {
    console.log("action_name -" + data.err_action_name);
    console.log("error -" + data.err_message);
  };

  exports.make_bank_payment = function (data) {
    var callbackData = {};
    callbackData.action = "make_bank_payment";
    callbackData.action_data = data;
    if (typeof window.Android !== "undefined") {
      window.Android.callbackNative(JSON.stringify(callbackData));
    } else if (isMobile.iOS() && typeof window.webkit !== "undefined") {
      window.webkit.messageHandlers.callbackNative.postMessage(callbackData);
    }
  };

  exports.show_top_bar = function (data) {
    var callbackData = {};
    callbackData.action = "show_top_bar";
    if (data) {
      callbackData.action_data = data;
    }
    if (typeof window.Android !== "undefined") {
      window.Android.callbackNative(JSON.stringify(callbackData));
    } else if (isMobile.iOS() && typeof window.webkit !== "undefined") {
      window.webkit.messageHandlers.callbackNative.postMessage(callbackData);
    }
  };

  exports.hide_top_bar = function () {
    var callbackData = {};
    callbackData.action = "hide_top_bar";
    if (typeof window.Android !== "undefined") {
      window.Android.callbackNative(JSON.stringify(callbackData));
    } else if (isMobile.iOS() && typeof window.webkit !== "undefined") {
      window.webkit.messageHandlers.callbackNative.postMessage(callbackData);
    }
  };

  exports.open_browser = function (data) {
    var callbackData = {};
    callbackData.action = "open_browser";
    callbackData.action_data = data;
    if (typeof window.Android !== "undefined") {
      window.Android.callbackNative(JSON.stringify(callbackData));
    } else if (isMobile.iOS() && typeof window.webkit !== "undefined") {
      window.webkit.messageHandlers.callbackNative.postMessage(callbackData);
    }
  };

  let listeners = [];

  exports.add_listener = function (listener) {
    const listenerType = get(listener,'type','');
    let newListenerList = [];
    if(listenerType === 'back_pressed') {
      newListenerList = filter(listeners, function (el) {
        return el.type !== listenerType;
      })
      newListenerList.push(listener)
      listeners = newListenerList;
    } else {
      listeners.push(listener);
    }
  }

  exports.remove_listener = function (listener) {
    let newListenerList = filter(listeners, function (el) {
      return el.type === 'back_pressed';
    })
    listeners = newListenerList;
  }

  exports.back_pressed = function () {
    for (let i = 0, j = listeners.length; i < j; i++) {
      let l = listeners[i];

      if (l.type === 'back_pressed') {
        l.go_back();
      }
    }
  }

  exports.get_device_data = function (listener) {
    console.log("get device data")
    listeners.push(listener);
    let callbackData = {};
    callbackData.action = 'get_device_data';
    if (typeof window.Android !== 'undefined') {
      window.Android.callbackNative(JSON.stringify(callbackData));
    } else if (isMobile.iOS() && typeof window.webkit !== 'undefined') {
      window.webkit.messageHandlers.callbackNative.postMessage(callbackData);
    } else {
      const onLocationFetchSuccess = (position) => {
        let data = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          location_permission_denied: false
        }
        window.callbackWeb.send_device_data(data);
      };

      const onLocationFetchFailure = () => {
        let data = {
          location_permission_denied: true
        }
        window.callbackWeb.send_device_data(data);
      };

      if(!isEmpty(navigator.permissions)) {
        navigator.permissions.query({
          name: 'geolocation'
        }).then(function(result) {
            
          navigator.geolocation.getCurrentPosition(onLocationFetchSuccess, onLocationFetchFailure);
    
          if (result.state === 'denied') {
            onLocationFetchFailure();
          }
  
          /*
            onchange does not currently work in Mozilla due to a bug in Mozilla itself.
            The same behaviour is now also handled using onLocationFetchFailure passed into
            geolocation.getCurrentPosition's error callback param
          */
          result.onchange = function() {
            if (result.state === 'denied') {
              onLocationFetchFailure();
            }
          }
        })
      } else {
        navigator.geolocation.getCurrentPosition(onLocationFetchSuccess, onLocationFetchFailure);
      }
    }

    // for testing added
    // if(getConfig().Web) {
    //   window.callbackWeb.send_device_data();
    // }

  }

  function set_session_storage(key, value) {
    if(typeof value === 'object') {
      value = JSON.stringify(value);
    }
    window.sessionStorage.setItem(key, value);
  }

  exports.send_device_data = function (data_json_str) {
    let json_data = {};

    if(getConfig().Web) {
      json_data = {
        'location': {
          lat: data_json_str.latitude || '',
          lng: data_json_str.longitude || ''
        },
        'location_permission_denied': data_json_str.location_permission_denied,
        nsp: "ABC",
        device_id: "0000000000000000"
      }
    } else {
      if (data_json_str !== "" && typeof data_json_str === "string") {
        json_data = JSON.parse(data_json_str);
      } else {
        json_data = data_json_str;
      }
    }

    for (let j = 0; j < listeners.length; j++) {
      let lis = listeners[j];
      if (lis.type === 'location_nsp_received') {
        lis.location_nsp_received(json_data);
        break;
      }
    }
  }

  exports.sendEvent = function (message) {
    window.parent.postMessage(message, "*");
  };
  
  exports.eventCallback = function (data) {
    // events for partner uses
    var callbackData = {};
    callbackData.action = "event_callback";
    callbackData.action_data = data;
    if (typeof window.Android !== "undefined") {
      window.Android.callbackNative(JSON.stringify(callbackData));
    } else if (isMobile.iOS() && typeof window.webkit !== "undefined") {
      window.webkit.messageHandlers.callbackNative.postMessage(callbackData);
    }
  };
  exports.return_data = function (data_json_str) {
    var json_data = {};
    if (data_json_str !== "" && typeof data_json_str === "string") {
      json_data = JSON.parse(data_json_str);
    } else if (typeof data_json_str === "object") {
      json_data = data_json_str;
    }
    set_session_storage("currentUser", true);
    set_session_storage('is_secure', true);

    if (json_data?.partner) {
      const partner = getPartnerName(json_data.partner.toLowerCase());
      set_session_storage("partner", partner);
      store.dispatch(setPartner(partner))
      eventManager.emit(EVENT_MANAGER_CONSTANTS.updateAppTheme);
    }

    if (json_data?.sdk_capabilities) {
      set_session_storage("sdk_capabilities", json_data.sdk_capabilities);
    }

    if (json_data?.user_data) {
      const user = json_data.user_data.user;
      const kyc = json_data.user_data.kyc;
      const bankList = json_data.user_data.bank_list;
      const firstLogin = json_data.user_data.firstLogin;
      set_session_storage("dataSettedInsideBoot", true);
      set_session_storage("user", user);
      set_session_storage("kyc", kyc);
      set_session_storage("banklist", bankList);
      set_session_storage("firstlogin", firstLogin);
      store.dispatch(updateAppStorage({ firstLogin: firstLogin }))
      if (json_data.user_data.partner.partner_code) {
        const partner = getPartnerName(json_data.user_data.partner?.partner_code?.toLowerCase());
        set_session_storage("partner", partner);
        store.dispatch(setPartner(partner))
        eventManager.emit(EVENT_MANAGER_CONSTANTS.updateAppTheme);
      }
      store.dispatch(setKyc(kyc))
      store.dispatch(setUser(user))
      store.dispatch(setBankList(bankList))
    }

    if (json_data?.callback_version) {
      set_session_storage("callback_version", json_data.callback_version);
    }

    if (json_data?.android_sdk_version_code) {
      set_session_storage("android_sdk_version_code", json_data.android_sdk_version_code);
    }
  
    if (json_data?.ios_sdk_version_code) {
      set_session_storage("ios_sdk_version_code", json_data.ios_sdk_version_code);
    }
  }

  exports.set_content_data = function (data) {
    let contentData = {};
    if (data !== "" && typeof data === "string") {
      contentData = JSON.parse(data);
    } else if (typeof data === "object") {
      contentData = data;
    }
    if(!isEmpty(contentData?.upi_apps)) {
      set_session_storage("upiApps", contentData.upi_apps);
    }
  }

})(window.callbackWeb ? window.callbackWeb : (window.callbackWeb = {}));