export const PAYMENT_SUMMARY_DATA = {
  upgrade: {
    title: "Upgrading to trading and demat account",
    subtitle: "Stocks, IPOs, NCDs, SGBs",
    buttonTitle: "Continue to Pay",
  },
  default: {
    title: "Trading & Demat + Mutual Fund account",
    subtitle: "Stocks, Mutual funds, IPOs, NCDs, SGBs",
    buttonTitle: "PAY NOW",
  },
};

export const PAYMENT_STATUS_DATA = {
  success: {
    title: "Payment successful",
    subtitle:
      "Last step remaining! Now eSign to complete opening your Trading & Demat + Mutual Fund account",
    buttonTitle: "Continue",
    icon: "eq_pg_success.svg",
    screenName: "payment_success",
    isSuccess: true,
    id: "success",
  },
  failed: {
    title: "Payment failed",
    subtitle:
      "Any money debited will be refunded back to your account in 5-7 working days",
    buttonTitle: "RETRY",
    icon: "error_illustration.svg",
    screenName: "payment_failed",
    isSuccess: false,
    id: "fail",
  },
};

export const getAocPaymentStatusData = ({ amount, gst, totalAmount }) => {
  return {
    title: "Payment details",
    data: [
      {
        title: "Amount",
        amount: amount,
        dataAid: "price",
      },
      {
        title: "GST",
        amount: gst,
        dataAid: "gst",
        showDivider: true,
        className: "aoc-ps-gst",
      },
      {
        title: "Total",
        amount: totalAmount,
        className: "aoc-total-amount",
        showDivider: true,
        dataAid: "totalAmount",
      },
    ],
  };
};

export const AOC_STORAGE_CONSTANTS = {
  AOC_PAYMENT_DATA: "aocPaymentData",
};

export const getAocPaymentSummaryData = ({ amount, gst, totalAmount, gstPercentage }) => {
  return {
    title: "Payment details",
    data: [
      {
        title: "Account opening fees",
        amount: amount,
        dataAid: "price",
      },
      {
        title: `GST (${gstPercentage}%)`,
        amount: gst,
        dataAid: "gst",
        showDivider: true,
        className: "aoc-ps-gst",
      },
      {
        title: "Amount payable",
        amount: totalAmount,
        showDivider: true,
        dataAid: "totalAmount",
        className: "aoc-ps-total",
      },
    ],
  };
};

export const UNIFIED_JOURNEY_FEATURES_LIST = ["Stocks", "F&O", "IPO", "Mutual funds"];

export const UNIFIED_JOURNEY_BENEFITS = [
  {
    label: 'Single KYC for all investments',
    icon: 'iv_document.svg'
  },
  {
    label: 'Affordable brokerage & fees',
    icon: 'iv_savings.svg'
  },
]