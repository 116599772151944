import { combineReducers } from "redux";
import { businessLogicReducers } from "businesslogic/dataStore/reducers";
import webAppConfig from "./webAppThemeConfig";
import webAppStorage from "./webAppStorage";
import equityConnector from "./equityConnector";
import { createAction } from "@reduxjs/toolkit";

const MODULE_PREFIX = "[ WEB APP ]";

const Actions = {
  resetStoreData: `${MODULE_PREFIX} resetStoreData`,
};

// actions
export const resetStoreData = createAction(Actions.resetStoreData);

const appReducer = combineReducers({
  ...businessLogicReducers,
  webAppConfig,
  webAppStorage,
  equityConnector,
});

const rootReducer = (state, action) => {
  if (action.type === Actions.resetStoreData) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
