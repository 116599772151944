import React from "react";
import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import { useSelector } from "react-redux";
import { getAppThemeConfig } from "../dataLayer/reducers/webAppThemeConfig";

const ThemeWrapper = ({ children }) => {
  const theme = useSelector(getAppThemeConfig);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={{...theme}}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeWrapper;
