import React, { useMemo } from "react";
import InputField from "../../designSystem/molecules/InputField";
import ThemeWrapper from "../../theme/ThemeWrapper";
import { camelCase } from "lodash-es";

const InputFieldNew = (props) => {
  const { width, dataAid, dataAidSuffix, type, label, ...restProps } = props;
  const getInputFieldDataAid = () => {
    if (type === "date") {
      return dataAid || "dob";
    } else {
      return dataAidSuffix || dataAid || camelCase(label?.replace(`'s`, ""));
    }
  };
  const dataAidValue = useMemo(getInputFieldDataAid, [dataAid, dataAidSuffix]);
  return (
    <ThemeWrapper>
      <InputField
        type={type}
        label={label}
        dataAid={dataAidValue}
        fullWidth={width}
        {...restProps}
      />
    </ThemeWrapper>
  );
};

export default InputFieldNew;
