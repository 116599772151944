/* eslint-disable no-unused-expressions */
import React, { useEffect } from 'react';
import { isEmpty } from 'lodash-es';
import { useDispatch } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { setConnectorConfig } from '../../dataLayer/reducers/equityConnector';
import { getConfig, getConnectorDataAndName } from '../../utils/functions';

export default function UnAuthenticatedRoute({ children, component, ...rest }) {
  const config = getConfig();
  const { isLoggedIn, searchParams, smallcase } = config;
  const dispatch = useDispatch();

  useEffect(() => {
    const connectorConfig = getConnectorDataAndName();
    if (!isEmpty(connectorConfig) && smallcase) {
      dispatch(setConnectorConfig(connectorConfig));
    }
  }, [smallcase]);

  const isUnAuthenticatedPath = (path) => {
    const unAuthenticatedPaths = [
      "/login",
      "/forgot-pin",
      "/logout",
      "/prepare",
      "/partner-authentication",
      "/rm-login"
    ];
    const pathname = unAuthenticatedPaths.find(el => path.includes(el));
    return !!pathname;
  }

  if (isLoggedIn) {
    return (
      <Route
        {...rest}
        render={({ location }) => {
          const fromState = location?.state?.fromState
          const path = (!fromState || isUnAuthenticatedPath(fromState)) ? '/' : fromState;

          // Alternate Option ---------------
          // const navigate = navigateFunc.bind(props)
          // navigate(path, data);
          // -------------------------------------

          return <Redirect
            push={true}
            to={{
              pathname: path,
              state: {
                from: location,
                goBack: "/"
              },
              search: searchParams,
            }}
          />
        }}
      />
    );
  }

  return (
    <Route {...rest} component={component}>
      {children}
    </Route>
  );
}