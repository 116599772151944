const calcLineHeight = (fontSize, factor) => `${fontSize * factor}px`;
const headingTextFactor = 1.3;
const bodyTextFactor = 1.6;

// for custom variants, we are passing the fontFamily with the variant config itself.
const typographyVariantsConfig = (colors = {}, partnerConfig = {}) => ({
  heading1: {
    fontSize: 28,
    color: colors?.content?.primary,
    fontWeight: partnerConfig?.fontWeight?.Bold,
    lineHeight: calcLineHeight(28, headingTextFactor),
    fontFamily: partnerConfig?.fontFamily?.join(','),
  },
  heading2: {
    fontSize: 22,
    color: colors?.content?.primary,
    fontWeight: partnerConfig?.fontWeight?.Medium,
    lineHeight: calcLineHeight(22, headingTextFactor),
    fontFamily: partnerConfig?.fontFamily?.join(','),
  },
  heading3: {
    fontSize: 18,
    color: colors?.content?.primary,
    fontWeight: partnerConfig?.fontWeight?.Medium,
    lineHeight: calcLineHeight(18, headingTextFactor),
    fontFamily: partnerConfig?.fontFamily?.join(','),
  },
  heading4: {
    fontSize: 16,
    color: colors?.content?.primary,
    fontWeight: partnerConfig?.fontWeight?.Medium,
    lineHeight: calcLineHeight(16, headingTextFactor),
    fontFamily: partnerConfig?.fontFamily?.join(','),
  },
  body1: {
    fontSize: 14,
    color: colors?.content?.primary,
    fontWeight: partnerConfig?.fontWeight?.Medium,
    lineHeight: calcLineHeight(14, bodyTextFactor),
    fontFamily: partnerConfig?.fontFamily?.join(','),
  },
  body2: {
    fontSize: 14,
    color: colors?.content?.primary,
    fontWeight: partnerConfig?.fontWeight?.Regular,
    lineHeight: calcLineHeight(14, bodyTextFactor),
    fontFamily: partnerConfig?.fontFamily?.join(','),
  },
  body3: {
    fontSize: 12,
    color: colors?.content?.primary,
    fontWeight: partnerConfig?.fontWeight?.Bold,
    lineHeight: calcLineHeight(12, bodyTextFactor),
    fontFamily: partnerConfig?.fontFamily?.join(','),
  },
  body4: {
    fontSize: 12,
    color: colors?.content?.primary,
    fontWeight: partnerConfig?.fontWeight?.Medium,
    lineHeight: calcLineHeight(12, bodyTextFactor),
    fontFamily: partnerConfig?.fontFamily?.join(','),
  },
  body5: {
    fontSize: 12,
    color: colors?.content?.primary,
    fontWeight: partnerConfig?.fontWeight?.Regular,
    lineHeight: calcLineHeight(12, bodyTextFactor),
    fontFamily: partnerConfig?.fontFamily?.join(','),
  },
  body6: {
    fontSize: 10,
    color: colors?.content?.primary,
    fontWeight: partnerConfig?.fontWeight?.Regular,
    lineHeight: calcLineHeight(10, bodyTextFactor),
    fontFamily: partnerConfig?.fontFamily?.join(','),
  },
  body7: {
    fontSize: 10,
    color: colors?.content?.primary,
    fontWeight: partnerConfig?.fontWeight?.Medium,
    lineHeight: calcLineHeight(10, bodyTextFactor),
    fontFamily: partnerConfig?.fontFamily?.join(','),
  },
  body8: {
    fontSize: 16,
    color: colors?.content?.primary,
    fontWeight: partnerConfig?.fontWeight?.Regular,
    lineHeight: calcLineHeight(16, bodyTextFactor),
    fontFamily: partnerConfig?.fontFamily?.join(','),
  },
  body9: {
    fontSize: 12,
    color: colors?.content?.primary,
    fontWeight: partnerConfig?.fontWeight?.Medium,
    lineHeight: calcLineHeight(12, bodyTextFactor),
    fontFamily: partnerConfig?.fontFamily?.join(','),
  },
  actionText: {
    fontSize: 16,
    color: colors?.content?.primary,
    fontWeight: partnerConfig?.fontWeight?.Medium,
    lineHeight: '21px',
    fontFamily: partnerConfig?.fontFamily?.join(','),
    textTransform: 'uppercase'
  },
});

// for custom variants, we are passing the fontFamily with the variant config itself.
const baseTypographyConfig = (colors={}, partnerConfig={}) => ({
  ...typographyVariantsConfig(colors, partnerConfig),
  // the below font family is for the MUI components.
  fontFamily: partnerConfig?.fontFamily?.join(','),
});

export default baseTypographyConfig;

export const customTypographyVariantProps = (colors = {}, partnerConfig = {}) => {
  const mapper = [];
  const typographyVariants  = typographyVariantsConfig(colors, partnerConfig);
  const variantKeys = Object.keys(typographyVariants);
  // eslint-disable-next-line no-unused-expressions
  variantKeys?.forEach((header) => {
    const fontWeightKeys = Object.keys(partnerConfig?.fontWeight);
    fontWeightKeys.forEach((el) => {
      const data = {
        props: { variant: header, weight: el },
        style: {
          fontWeight: partnerConfig?.fontWeight[el],
        },
      };
      mapper.push(data);
    });
  });

  const additionalVariants = [{
    props: {allcaps: 1},
    style: {
      textTransform: 'uppercase'
    },
  }];
  mapper.push(...additionalVariants);
  return mapper;
};
