import { createAction, createReducer } from "@reduxjs/toolkit";
import { get } from "lodash-es";
import getTheme from "../../theme";
import getPartnerThemeData from "../../theme/utils";
import { getIconColorsList } from "../../business/common/helper";

const MODULE_PREFIX = "[ WEB ]";

const Actions = {
  setAppThemeConfig: `${MODULE_PREFIX} setAppThemeConfig`,
  setIconColors: `${MODULE_PREFIX} setIconColors`,
  setPartnerThemeConfig: `${MODULE_PREFIX} setPartnerThemeConfig`,
};

// actions
export const setIconColors = createAction(Actions.setIconColors);
export const setAppThemeConfig = createAction(Actions.setAppThemeConfig);
export const setPartnerThemeConfig = createAction(
  Actions.setPartnerThemeConfig
);

// selector
export const getPartnerThemeConfig = (state) => {
  const partnerThemeConfig = get(state, "webAppConfig.partnerThemeConfig", {});
  return partnerThemeConfig;
};

export const getAppThemeConfig = (state) => {
  const appThemeConfig = get(state, "webAppConfig.appThemeConfig", {});
  return appThemeConfig;
};

export const getAppIconColors = (state) => {
  const iconColors = get(state, "webAppConfig.iconColors", []);
  return iconColors;
};

// initial state
const partnerThemeData = getPartnerThemeData();
const theme = getTheme();
const iconColors = getIconColorsList(partnerThemeData.colors)
const initialState = {
  appThemeConfig: theme,
  partnerThemeConfig: partnerThemeData,
  iconColors,
};

// reducer
export default createReducer(initialState, (builder) => {
  builder.addCase(setAppThemeConfig, (state, action) => {
    state.appThemeConfig = action.payload;
  });
  builder.addCase(setPartnerThemeConfig, (state, action) => {
    state.partnerThemeConfig = action.payload;
  });
  builder.addCase(setIconColors, (state, action) => {
    state.iconColors = action.payload;
  });
});
