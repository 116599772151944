import { createAction, createReducer } from "@reduxjs/toolkit";
import { get } from "lodash-es";

const MODULE_PREFIX = "[ WEBAPP STORAGE ]";

const Actions = {
  setGoBackFlowPath: `${MODULE_PREFIX} setGoBackFlowPath`,
};

// actions
export const setGoBackFlowPath = createAction(Actions.setGoBackFlowPath);

// selector
export const getGoBackFlowPath = (state) => {
  const flowPath = get(state, "webAppStorage.goBackFlowPath", '');
  return flowPath;
};

// initial state
const initialState = {
  goBackFlowPath: '',
};

// reducer
export default createReducer(initialState, (builder) => {
  builder.addCase(setGoBackFlowPath, (state, action) => {
    state.goBackFlowPath = action.payload;
  });
});
