import { useHistory, useLocation } from "react-router-dom"
import { navigate as navigateFunc } from "../../utils/functions";

export const useNavigationHook = (navigationProps) => {
  const history = useHistory();
  const location = useLocation();

  const navigate = navigateFunc.bind({ history, location });

  return { navigate, history, location };
}