import React from 'react';
import LibTypography from '@mui/material/Typography';
import PropTypes, { oneOfType } from 'prop-types';
import { Skeleton } from '@mui/material';

const Typography = ({ allCaps, children, dataAid, isLoading, loaderWidth = 100, ...restProps }) => {
  return (
    <LibTypography allcaps={allCaps ? 1 : 0} data-aid={`tv_${dataAid}`} {...restProps}>
      {isLoading
        ? <Skeleton width={loaderWidth} />
        : children
      }
    </LibTypography>
  );
};

Typography.propTypes = {
  dataAid: oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  allCaps: PropTypes.bool,
  isLoading: PropTypes.bool,
  loaderWidth: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}

export default Typography;
