export const EQUITY_ONBOARDING_CAROUSELS = [
  {
    icon: "invest_with_confidence.svg",
    iconDataAid: "investWithConfidence",
    title: "Invest with confidence",
    subtitle: "Trusted by your bank & loved by millions of investors",
  },
  {
    icon: "one_investment_account.svg",
    title: "One investment account",
    iconDataAid: "oneInvestmentAccount",
    subtitle: "For Stocks, IPO, F&O, Mutual Funds, NPS & more",
  },
  {
    icon: "withdraw_funds.svg",
    iconDataAid: "withdrawFunds",
    title: "Withdraw funds anytime, anywhere",
    subtitle: "Get cash in your savings account when you need it the most",
  },
];

export const ONBOARDING_CAROUSELS = [
  {
    icon: "invest_with_confidence.svg",
    iconDataAid: "investWithConfidence",
    title: "Invest with confidence",
    subtitle: "Trusted by your bank & loved by millions of investors",
  },
  {
    icon: "invest_directly.svg",
    title: "Invest directly from savings account",
    iconDataAid: "investDirectly",
    subtitle: "Choose from 5,000+ mutual funds",
  },
  {
    icon: "withdraw_funds.svg",
    iconDataAid: "withdrawFunds",
    title: "Withdraw funds anytime, anywhere",
    subtitle: "Get cash in your savings account when you need it the most",
  },
];

export const PLATFORM_MOTIVATORS = [
  {
    icon: "one_kyc.json",
    title: "One KYC for Mutual Funds & Stocks",
    subtitle: "Easy. Paperless. Secure",
    dataAid: "oneKyc",
    id: "equityKyc",
  },
  {
    icon: "withdrawal.json",
    title: "Convenient & easy withdrawals",
    subtitle: "Get money in savings bank A/c.",
    dataAid: "withdrawal",
  },
  {
    icon: "account_manage.json",
    title: "Manage your SIPs with ease",
    subtitle: "Pause, edit, cancel in 1 tap ",
    dataAid: "manageSips",
  },
  // {
  //   icon: "order_place.json",
  //   title: "Seamless order placement",
  //   subtitle: "Select fund, add amount & pay!",
  //   dataAid: "orderPlacement",
  // },
  {
    icon: "insights.json",
    title: "Track investments real-time",
    subtitle: "With in-depth portfolio tracking",
    dataAid: "trackInvestment",
  },
];

/*

  Marketing Banners

  Id details
  nfoCheckout : nfo investment, manadatory flag - (isin), optional flags - (startDate & endDate)
  nfo : nfo funds screens, optional flags - (startDate & endDate)
  stocksBanner : stocks banner visible all equity enabled users
  ipoBanner: ipo banner visible for all equity enabled users, type : ipo, ncd, sgb, fpo, buyback
  stocks: stocks banner visible for all equity kyc completed users
  ipo: ipo banner visible all for equity kyc completed users
  smallcase: smallcase banner visible all for equity kyc completed users
  equityKyc: kyc banner visible all for equity enabled & kyc inprogress users
  freedomplan: Banner displayed for freedom plan enabled users
  refer: Share referral banner
  elss : save tax banner
  education: child education banner
  taxFiling: tax file banner
  buildwealth: start sip banner

  Note: 
  1. For internal screen navigation, id should be key of WEBAPP_LANDING_PATHNAME_MAPPER (webappLanding/constants.js)
  2. actionUrl is used to open 3rd party links

*/

const NFO_MARKETING_BANNERS = [
  {
    image: "nfo.svg",
    id: "nfo",
    endDate: "01/22/2024",
  },
  {
    image: "nfo1.svg",
    id: "nfo",
    endDate: "01/31/2024",
  },
];

const COMMON_EQUITY_MF_BANNERS = [
  {
    image: "iv_elss.svg",
    id: "elss",
    endDate: "03/31/2024",
  },
];

const COMMON_EQUITY_BANNERS = [
  ...COMMON_EQUITY_MF_BANNERS,
  {
    image: "iv_free_demat.svg",
    id: "equityKyc",
    endDate: "03/31/2024",
  },
  {
    image: "iv_smallcase.svg",
    id: "smallcase",
    endDate: "03/31/2024",
  },
];

const COMMON_MF_BANNERS = [
  ...COMMON_EQUITY_MF_BANNERS,
  {
    image: "iv_buildwealth.svg",
    id: "buildwealth",
    endDate: "03/31/2024",
  },
];

export const MF_MARKETING_BANNERS = [
  ...NFO_MARKETING_BANNERS,
  ...COMMON_MF_BANNERS,
];

export const EQUITY_MARKETING_BANNERS = [
  ...NFO_MARKETING_BANNERS,
  ...COMMON_EQUITY_BANNERS,
];
