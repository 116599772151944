import React, { useMemo } from 'react';
import NavBar from './NavBar';
import Drawer from './Drawer';
import RightContent from './InvestInfo';
import 'common/theme/Style.scss';
import './DesktopLayout.scss';
import { useNavigationHook } from '../common/customHooks/useNavigation';
import { isDesktopLayout } from '../utils/functions';

const DesktopLayout = (props) => {
  const { location } = useNavigationHook();
  const isDesktopLayoutEnabled = useMemo(isDesktopLayout, [location.pathname]);
  if (!isDesktopLayoutEnabled) {
    return props.children;
  }
  return (
    <div className='main-container' data-aid='desktop-layout-main-container'>
      <NavBar />
      <Drawer />
      <div className='middle-content' data-aid='middle-content'>
        <div className='feature-container' data-aid='feature-container'>{props.children}</div>
        <div className='right-content'>
          <RightContent />
        </div>
      </div>
    </div>
  );
};

export default DesktopLayout;
