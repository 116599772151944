import { createAction, createReducer } from "@reduxjs/toolkit";
import { get } from "lodash-es";

const MODULE_PREFIX = "[ EQUITY CONNECTOR ]";

const Actions = {
  setConnectorConfig: `${MODULE_PREFIX} setConnectorConfig`,
};

// actions
export const setConnectorConfig = createAction(Actions.setConnectorConfig);

// selector
export const getConnectorConfig = (state) => {
  const connectorConfig = get(state, "equityConnector.connectorConfig", {});
  return connectorConfig;
};

// initial state
const initialState = {
  connectorConfig: {
    connectorData: {},
    connectorName: "",
  },
};

// reducer
export default createReducer(initialState, (builder) => {
  builder.addCase(setConnectorConfig, (state, action) => {
    state.connectorConfig = action.payload;
  });
});
