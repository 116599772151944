import { get } from "lodash-es";

export const switchVariantsConfig = (colors = {}) => {
  const tertiary = get(colors, "content.tertiary", "");
  const trackCheckedColor = get(colors, "primary.400", "");
  const primary = get(colors, "primary.brand", "");
  const primaryDisabled = get(colors, "primary.200", "");
  const cadetBlue = get(colors, "supporting.cadetBlue", "");
  const athensGrey = get(colors, "supporting.athensGrey", "");

  return [
    {
      props: { checked: true },
      style: {
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
          backgroundColor: trackCheckedColor,
          opacity: 1
        },
        '.MuiSwitch-thumb': {
          backgroundColor: primary,
        }
      },
    },
    {
      props: { checked: false },
      style: {
        '.MuiSwitch-track': {
          backgroundColor: cadetBlue,
          opacity: 1
        },
        '.MuiSwitch-thumb': {
          backgroundColor: tertiary,
        }
      },
    },
    {
      props: { isTwoWay: true, checked: false },
      style: {
        '& .MuiSwitch-switchBase + .MuiSwitch-track': {
          backgroundColor: trackCheckedColor,
          opacity: 1
        },
        '.MuiSwitch-thumb': {
          backgroundColor: primary,
        }
      },
    },
    {
      props: { disabled: true, checked: true },
      style: {
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
          backgroundColor: primaryDisabled
        },
        '& .MuiSwitch-thumb': {
          backgroundColor: primaryDisabled,
        }
      }
    },
    {
      props: { disabled: true, checked: false },
      style: {
        '& .MuiSwitch-track': {
          backgroundColor: athensGrey,
          opacity: "1 !important"
        },
        '& .MuiSwitch-thumb': {
          backgroundColor: athensGrey,
        }
      },
    },
    {
      props: { disabled: true, checked: false, isTwoWay: true },
      style: {
        '& .MuiSwitch-switchBase + .MuiSwitch-track': {
          backgroundColor: primaryDisabled
        },
        '& .MuiSwitch-thumb': {
          backgroundColor: primaryDisabled,
        }
      },
    },
  ];
}

export const switchStyleOverRides = (colors = {}) => {
  const white = get(colors, "supporting.white", "");

  return {
    thumb: {
      border: `2px solid ${white}`,
      boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.16)',
      opacity: 1
    },
  };
};
