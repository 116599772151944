import React from "react";
import PropTypes from "prop-types";
import { Skeleton } from "@mui/material";

const IconSkeleton = ({ className, size, height, width, style }) => {

  const imgHeight = height || size;
  const imgWidth = width || size;

  return (
    <Skeleton
      className={className}
      style={{ borderRadius: "8px", ...style }}
      variant="rectangular"
      width={parseInt(imgWidth, 10)}
      height={parseInt(imgHeight, 10)}
    />
  );
};

IconSkeleton.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object,
};

IconSkeleton.defaultProps = {
  size: 32,
  style: {},
  className: "",
};

export default IconSkeleton;
