import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPartner,
  updateAppStorage,
} from "businesslogic/dataStore/reducers/app";
import {
  setAppThemeConfig,
  setIconColors,
  setPartnerThemeConfig,
} from "../../dataLayer/reducers/webAppThemeConfig";
import getPartnerThemeData from "../../theme/utils";
import getTheme from "../../theme";
import { getIconColorsList } from "../../business/common/helper";
import { isDesktopLayout } from "../../utils/functions";
import { useNavigationHook } from "../customHooks/useNavigation";

const AppInitialization = () => {
  const dispatch = useDispatch();
  const { location } = useNavigationHook();
  const partner = useSelector(getPartner);
  const desktopLayout = useMemo(isDesktopLayout, [location.pathname]);

  const clearBottomsheetDisplays = () => {
    const bottomSheetsArr = [
      "isCampaignDisplayed",
      "isAuthVerificationDisplayed",
      "isKycBottomsheetDisplayed",
      "isPremiumBottomsheetDisplayed",
      "isClosureEsignReadyBottomsheetDisplayed",
      "isClosureRejectedBottomsheetDisplayed",
    ];

    let data = {};
    bottomSheetsArr.forEach((bottomSheet) => {
      data = {
        ...data,
        [bottomSheet]: false,
      };
    });
    dispatch(updateAppStorage(data));
  };

  useEffect(() => {
    clearBottomsheetDisplays();
  }, []);

  const updateThemeConfig = () => {
    const theme = getTheme();
    const partnerThemeData = getPartnerThemeData();
    const iconColors = getIconColorsList(partnerThemeData.colors);
    dispatch(setAppThemeConfig(theme));
    dispatch(setIconColors(iconColors));
    dispatch(setPartnerThemeConfig(partnerThemeData));
  };

  useEffect(() => {
    updateThemeConfig();
  }, [partner, desktopLayout]);

  return <div></div>;
};

export default AppInitialization;
