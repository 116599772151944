import React from 'react';
import IdleTimer from "react-idle-timer";
import { getConfig, navigate as navigateFunc } from './utils/functions';
import { withRouter } from 'react-router-dom';

// if idle time of 5mins logout user
// time out in milliseconds
const TIME_OUT = 60 * 5 * 1000;

const IdleTimerContainer = (props) => {
  const config = getConfig();
  const navigate = navigateFunc.bind(props);

  const onIdle = () => {
    if(config?.features?.isIdle && config.isLoggedIn) {
      navigate("/logout");
    }
  };

  return (
    <IdleTimer timeout = {TIME_OUT} onIdle = {onIdle} />
  )
};

export default withRouter(IdleTimerContainer);
